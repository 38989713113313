<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <div
      class="rounded-lg overflow-hidden  divide-y divide-gray-200 sm:divide-y-0 sm:grid sm:grid-cols-2 sm:gap-2"
    >
      <div
        class="rounded-md relative group bg-white shadow p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
      >
        <div>
          <span
            class="rounded-lg inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white"
          >
            <asom-icon icon="lastpass"></asom-icon>
          </span>
        </div>
        <div class="mt-8">
          <h3 class="text-lg font-medium">
            <div>
              <span class="absolute inset-0" aria-hidden="true"></span>
              Expired Items
            </div>
          </h3>
          <p class="mt-2 text-sm text-gray-500">
            Daily Notifications on Expired Items
          </p>
        </div>
        <button
          class="absolute focus:outline-none top-6 right-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
          @click="editExpireItems"
        >
          <asom-icon icon="pen"></asom-icon>
        </button>
      </div>

      <div
        class="rounded-md relative group bg-white shadow p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
      >
        <div>
          <span
            class="rounded-lg inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white"
          >
            <asom-icon icon="timelapse"></asom-icon>
          </span>
        </div>
        <div class="mt-8">
          <h3 class="text-lg font-medium">
            <div class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              Approaching Expiry
            </div>
          </h3>
          <p class="mt-2 text-sm text-gray-500">
            Noficiations before 10 days of expire
          </p>
        </div>
        <button
          class="absolute focus:outline-none top-6 right-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
          @click="editApproachingExpiry"
        >
          <asom-icon icon="pen"></asom-icon>
        </button>
      </div>

      <div
        class="rounded-md relative group bg-white shadow p-6 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500"
      >
        <div>
          <span
            class="rounded-lg inline-flex p-3 bg-purple-50 text-purple-700 ring-4 ring-white"
          >
            <asom-icon icon="studio"></asom-icon>
          </span>
        </div>
        <div class="mt-8">
          <h3 class="text-lg font-medium">
            <div class="focus:outline-none">
              <span class="absolute inset-0" aria-hidden="true"></span>
              Below Threshold Quantity
            </div>
          </h3>
          <p class="mt-2 text-sm text-gray-500">
            Notications when an item reaches below it`s thresold quatity
          </p>
        </div>
        <button
          class="absolute focus:outline-none top-6 right-6 text-gray-300 group-hover:text-gray-400"
          aria-hidden="true"
          @click="editBelowThreshold"
        >
          <asom-icon icon="pen"></asom-icon>
        </button>
      </div>
    </div>
    <asom-modal
      v-model="showEditModal"
      title="Confirmation"
      :dismissible="false"
    >
      <asom-alert v-if="error" :error-message="error" />
      <asom-form-field label="Frequency" required>
        <asom-input-select
          v-model="formData.frequency"
          :options="frequencyOptions"
        />
      </asom-form-field>
      <div class="flex flex-row-reverse pt-4 gap-4">
        <asom-button
          @click="onConfirmEdit"
          :disabled="isSubmittingEdit"
          :loading="isSubmittingEdit"
          text="Confirm"
        />
        <asom-button
          @click="toggleEditModal(false)"
          text="Cancel"
          variant="secondary"
        />
      </div>
    </asom-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      transactionLogs: [],
      error: null,
      showEditModal: false,
      isSubmittingEdit: false,
      formData: {
        frequency: null,
      },
    };
  },
  computed: {
    frequencyOptions() {
      return [];
    },
  },
  methods: {
    editExpireItems() {
      this.toggleEditModal(true);
    },
    editApproachingExpiry() {
      this.toggleEditModal(true);
    },
    editBelowThreshold() {
      this.toggleEditModal(true);
    },
    toggleEditModal(value) {
      this.showEditModal = value;
    },
    onConfirmEdit() {},
  },
};
</script>
